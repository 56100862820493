<template>
  <div>
    <div class="field-label">
      {{ placeholder }}
    </div>
    <SelectWithAutocomplete
      :value="value"
      :items="items"
      :multiple="mode === 1"
      :name="null"
      :required="required"
      :loading="loading"
      color="fields"
      filled
      item-text="text"
      item-value="id"
      return-object
      :error-messages="errorMessage || errors.value"
      :clearable="!required"
      @input="
        (newValue) => {
          onChange(newValue), $v.$touch();
        }
      "
      @blur="$v.$touch()"
    ></SelectWithAutocomplete>
  </div>
</template>

<script>
import abstractField from "@/utils/AbstractField";
import dictService from "@/services/DictionaryService";
import validationThings from "@/mixins/validation-things";
import SelectWithAutocomplete from "@/components/Basic/SelectWithAutocomplete";
export default {
  components: { SelectWithAutocomplete },
  name: "SelectField",
  mixins: [abstractField, validationThings],
  props: {
    dictId: Number,
    parentId: Number,
    parentValue: Number,
    mode: { type: Number, default: 0 },
    dictParamValueId: [String, Number],
    paramValue: String,
  },
  mounted() {
    this.getItems();
    this.getSubDictionary();

    if (this.paramValue && this.dictParamValueId) {
      if (this.mode === 1 && this.paramValue.includes(",")) {
        // multi-select
        const ids = this.dictParamValueId.split(",");
        const texts = this.paramValue.split(",");
        if (ids.length === texts.length) {
          this.value = [];
          for (let i = 0; i < ids.length; i++) {
            this.value[i] = { id: +ids[i], text: texts[i] };
          }
        }
      } else {
        this.value = { id: +this.dictParamValueId, text: this.paramValue };
      }
    }
  },
  data() {
    return {
      items: [],
      inMemory: [],
      loading: true,
    };
  },
  watch: {
    parentValue(newVal, oldVal) {
      if (oldVal !== -1) this.value = [];
      this.getSubDictionary();
    },
    paramValue(newParamValue) {
      this.value = this.dictId
        ? { id: +this.dictParamValueId, text: newParamValue }
        : newParamValue;
    },
  },
  validations: {
    value: {
      required: (value, vm) => {
        return vm.required
          ? Array.isArray(value)
            ? !!value[0]
            : !!value
          : true;
      },
    },
  },
  methods: {
    onChange(newValue) {
      this.$emit("onChange", {
        id: this.id,
        value: newValue,
      });
    },
    getItems() {
      if (this.parentId) {
        this.loading = false;
        return;
      }
      this._getDictionary();
    },
    _getDictionary() {
      dictService
        .GetDictionary(
          this.dictId,
          this.dictParamValueId == null ||
            this.dictParamValueId.toString().includes(",")
            ? null
            : this.dictParamValueId
        )
        .then(this._setItem);
    },
    getSubDictionary() {
      if (!this.parentValue || this.parentValue === -1) {
        this.items = [];
        return;
      }
      if (this.inMemory[this.parentValue]) {
        this.items = this.inMemory[this.parentValue];
        return;
      }
      this.loading = true;
      dictService
        .GetSubDictionary(this.dictId, this.parentValue, this.dictParamValueId)
        .then(this._setItem)
        .then(() => {
          this.inMemory[this.parentValue] = this.items;
        });
    },
    _setItem(response) {
      this.items = response;
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.v-input__slot:before {
  border-color: #fbfbfb !important;
}
::v-deep.v-input__icon--clear > i {
  font-size: 16px !important;
}
::v-deep.v-input__icon--append > i {
  font-size: 16px !important;
}

.field-label {
  font-size: 15px;
}
</style>
